import { APICore } from './apiCore';

const api = new APICore();
function getNotificationsApi(params: { groupId: string }) {
    const urlRoute = '/dashboard/notifications/';
    return api.get(`${urlRoute}`, params);
}
function getCustomerDashboardApi(params: { groupId: string }) {
    const urlRoute = '/dashboard/customer-dashboard/';
    return api.get(`${urlRoute}`, params);
}
export { getNotificationsApi, getCustomerDashboardApi }
