import { CompanyActionTypes } from './constants';

export type CompaniesActionType = {
    type:
    | CompanyActionTypes.API_RESPONSE_SUCCESS
    | CompanyActionTypes.API_RESPONSE_ERROR
    | CompanyActionTypes.GET_COMPANIES
    | CompanyActionTypes.GET_COMPANY_BY_ID
    | CompanyActionTypes.UPDATE_COMPANY_BY_ID
    | CompanyActionTypes.GET_USERS
    | CompanyActionTypes.GET_USER_BY_ID
    | CompanyActionTypes.UPDATE_USER_BY_ID
    | CompanyActionTypes.DELETE_USER_BY_ID
    | CompanyActionTypes.GET_DEVICES
    | CompanyActionTypes.GET_DEVICE_DETAILS
    | CompanyActionTypes.SYNC_COMPANIES
    payload: {} | any;
};

// common success
export const CompaniesApiResponseSuccess = (actionType: string, data: any | {}): CompaniesActionType => ({
    type: CompanyActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const CompaniesApiResponseError = (actionType: string, error: string): CompaniesActionType => ({
    type: CompanyActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
export const syncCompaniesAction = (): CompaniesActionType => ({
    type: CompanyActionTypes.SYNC_COMPANIES,
    payload: {},
});
export const getCompaniesAction = (groupId: string): CompaniesActionType => ({
    type: CompanyActionTypes.GET_COMPANIES,
    payload: { groupId: groupId },
});
export const getUsersAction = (): CompaniesActionType => ({
    type: CompanyActionTypes.GET_USERS,
    payload: {},
});
export const getUserByIdAction = (userId: number): CompaniesActionType => ({
    type: CompanyActionTypes.GET_USER_BY_ID,
    payload: {
        userId: userId,
    },
});
export const deleteUserByIdAction = (userId: number): CompaniesActionType => ({
    type: CompanyActionTypes.DELETE_USER_BY_ID,
    payload: {
        userId: userId,
    },
});
export const updateUserByIdAction = (userData: any): CompaniesActionType => ({
    type: CompanyActionTypes.UPDATE_USER_BY_ID,
    payload: {
        userData: userData,
    },
});
export const getDevicesAction = (groupId: string): CompaniesActionType => ({
    type: CompanyActionTypes.GET_DEVICES,
    payload: { groupId },
});

export const getCompanyByIdAction = (id?: any): CompaniesActionType => ({
    type: CompanyActionTypes.GET_COMPANY_BY_ID,
    payload: { id },
});

export const updateCompanyAction = (formState: any): CompaniesActionType => ({
    type: CompanyActionTypes.UPDATE_COMPANY_BY_ID,
    payload: { formState }, // Corrected parameter name here
});
export const getDeviceDetailsAction = (params: any) => ({
    type: CompanyActionTypes.GET_DEVICE_DETAILS,
    payload: { params },
});

export const uploadImageAction = (imageData: Blob) => ({
    type: CompanyActionTypes.UPLOAD_IMAGE,
    payload: { imageData },
});
export const createSupportTicketAction = (createTicket: any) => ({
    type: CompanyActionTypes.CREATE_SUPPORT_TICKET,
    payload: { createTicket },
})
