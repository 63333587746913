import { CompanyBranding } from './auth';
import { AuthActionTypes } from './constants';

export type AuthActionType = {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.CHANGE_PASSWORD
    | AuthActionTypes.VERIFY_USER
    | AuthActionTypes.RESET_PASSWORD_MAIL
        | AuthActionTypes.FORGOT_PASSWORD_CHANGE
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.GET_COMPANY_LOGIN;
    payload: {} | string;
};

type User = {
    id: number;
    email: string;
    name: string;
    role: string;
    isEmailVerified: boolean;
    companyId: number;
    createdAt: string;
    updatedAt: string;
    token: string;
    username?: string;
    branding?: CompanyBranding
};
// common success
export const authApiResponseSuccess = (actionType: string, data: User | {}): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (username: string, password: string): AuthActionType => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { username, password },
});

export const logoutUser = (): AuthActionType => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (fullname: string, email: string, password: string): AuthActionType => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { fullname, email, password },
});

export const forgotPassword = (username: string): AuthActionType => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { username },
});
export const sendRestMailAction = (username: string): AuthActionType => ({
    type: AuthActionTypes.RESET_PASSWORD_MAIL,
    payload: { username },
});

export const resetAuth = (): AuthActionType => ({
    type: AuthActionTypes.RESET,
    payload: {},
});

export const resetPassword = (resetData?: any): AuthActionType => ({
    type: AuthActionTypes.CHANGE_PASSWORD,
    payload: resetData,
});
export const verifyEmail = (resetData?: any): AuthActionType => ({
    type: AuthActionTypes.VERIFY_USER,
    payload: resetData,
});
export const getCompanyByName = (params?: any): AuthActionType => ({
    type: AuthActionTypes.GET_COMPANY_LOGIN,
    payload: params,
});
export const updateCompanyBrandingAction = (companyBranding: any) => ({
    type: AuthActionTypes.UPDATE_COMPANY_BRANDING,
    payload: companyBranding,
});