import { CompanyActionTypes } from "./constants";

const INIT_STATE = {
    companiesList: [],
    usersList: [],
    devicesList: [],
    deviceDetails: {},
    imageUploaded: {},
    updatedStatus: '',
    deletedStatus: '',
    supportTicketStatus: '',
    syncStatus: '',
    loading: false
}
type CompaniesActionType = {
    type:
    | CompanyActionTypes.API_RESPONSE_SUCCESS
    | CompanyActionTypes.API_RESPONSE_ERROR
    | CompanyActionTypes.GET_COMPANIES
    | CompanyActionTypes.GET_COMPANY_BY_ID
    | CompanyActionTypes.UPDATE_COMPANY_BY_ID
    | CompanyActionTypes.UPLOAD_IMAGE
    | CompanyActionTypes.GET_USERS
    | CompanyActionTypes.GET_DEVICES
    | CompanyActionTypes.GET_DEVICE_DETAILS
    | CompanyActionTypes.CREATE_SUPPORT_TICKET
    | CompanyActionTypes.SYNC_COMPANIES

    payload: {
        actionType?: string;
        data?: any | {};
        error?: string;
    };
};
type State = {
    companiesList?: any | {};
    deviceDetails?: any | {};
    companyDetailsById?: any | {};
    updatedStatus?: string;
    usersList?: any | [];
    imageUploaded?: any | {};
    loading?: boolean;
};
const Companies = (state: State = INIT_STATE, action: CompaniesActionType) => {
    switch (action.type) {
        case CompanyActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CompanyActionTypes.GET_COMPANIES: {
                    return {
                        ...state,
                        companiesList: action.payload.data,
                        loading: false,
                    };
                }
                case CompanyActionTypes.GET_COMPANY_BY_ID: {
                    return {
                        ...state,
                        companyDetailsById: action.payload.data,
                        loading: false,
                    };
                }
                case CompanyActionTypes.UPDATE_COMPANY_BY_ID: {
                    return {
                        ...state,
                        companyDetailsById: action.payload.data?.data,
                        updatedStatus: action.payload.data?.message,
                        loading: false,
                    };
                }
                case CompanyActionTypes.GET_USERS: {
                    return {
                        ...state,
                        usersList: action.payload.data,
                        loading: false,
                    };
                }
                case CompanyActionTypes.GET_USER_BY_ID: {
                    return {
                        ...state,
                        customerDetails: action.payload.data,
                        loading: false,
                    };
                }
                case CompanyActionTypes.UPDATE_USER_BY_ID: {
                    return {
                        ...state,
                        customerDetails: action.payload.data,
                        loading: false,
                    };
                }
                case CompanyActionTypes.DELETE_USER_BY_ID: {
                    return {
                        ...state,
                        usersList: action.payload.data?.data,
                        loading: false,
                    };
                }
                case CompanyActionTypes.GET_DEVICES: {
                    return {
                        ...state,
                        devicesList: action.payload.data,
                        loading: false,
                    };
                }
                case CompanyActionTypes.GET_DEVICE_DETAILS: {
                    return {
                        ...state,
                        deviceDetails: action.payload.data,
                        loading: false,
                    };
                }
                case CompanyActionTypes.CREATE_SUPPORT_TICKET: {
                    return {
                        ...state,
                        supportTicketStatus: action.payload.data?.message,
                        loading: false,
                    };
                }
                case CompanyActionTypes.UPLOAD_IMAGE: {
                    return {
                        ...state,
                        imageUploaded: action.payload.data,
                        loading: false,
                    };
                }
                case CompanyActionTypes.SYNC_COMPANIES: {
                    return {
                        ...state,
                        syncStatus: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CompanyActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CompanyActionTypes.GET_COMPANIES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.GET_COMPANY_BY_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.GET_USER_BY_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.UPDATE_USER_BY_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.UPDATE_COMPANY_BY_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.GET_USERS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.DELETE_USER_BY_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.GET_DEVICES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.GET_DEVICE_DETAILS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.CREATE_SUPPORT_TICKET: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.UPLOAD_IMAGE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CompanyActionTypes.SYNC_COMPANIES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        syncStatus: 'Error Syncing',
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CompanyActionTypes.GET_COMPANIES:
            return { ...state, loading: true, };
        case CompanyActionTypes.GET_COMPANY_BY_ID:
            return { ...state, loading: true, };
        case CompanyActionTypes.UPDATE_COMPANY_BY_ID:
            return { ...state, loading: true, };
        case CompanyActionTypes.GET_USERS:
            return { ...state, loading: true, };
        case CompanyActionTypes.GET_DEVICES:
            return { ...state, loading: true, };
        case CompanyActionTypes.GET_DEVICE_DETAILS:
            return { ...state, loading: true, };
        case CompanyActionTypes.UPLOAD_IMAGE:
            return { ...state, loading: true, };
        case CompanyActionTypes.CREATE_SUPPORT_TICKET:
            return { ...state, loading: true, };
        // case CompanyActionTypes.SYNC_COMPANIES:
        //     return { ...state, loading: true, };
        default:
            return { ...state };
    }
};
export default Companies;