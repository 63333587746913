export enum AuthActionTypes {
    API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',

    LOGIN_USER = '@@auth/LOGIN_USER',
    LOGOUT_USER = '@@auth/LOGOUT_USER',
    SIGNUP_USER = '@@auth/SIGNUP_USER',
    FORGOT_PASSWORD = '@@auth/FORGOT_PASSWORD',
    FORGOT_PASSWORD_CHANGE = '@@auth/FORGOT_PASSWORD_CHANGE',
    RESET_PASSWORD_MAIL = '@@auth/RESET_PASSWORD_MAIL',
    CHANGE_PASSWORD = '@@auth/CHANGE_PASSWORD',
    VERIFY_USER = '@@auth/VERIFY_USER',

    RESET = '@@auth/RESET',
    GET_COMPANY_LOGIN = '@@auth/GET_COMPANY_LOGIN',
    UPDATE_COMPANY_BRANDING = '@@auth/UPDATE_COMPANY_BRANDING',
}
