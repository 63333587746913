import { DashBoardActionTypes } from './constants';

export type DashBoardActionType = {
    type:
    | DashBoardActionTypes.API_RESPONSE_SUCCESS
    | DashBoardActionTypes.API_RESPONSE_ERROR
    | DashBoardActionTypes.GET_NOTIFICATIONS
    | DashBoardActionTypes.GET_DASHBOARD_DATA
    payload: {} | any;
};

// common success
export const DashBoardApiResponseSuccess = (actionType: string, data: any | {}): DashBoardActionType => ({
    type: DashBoardActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const DashBoardApiResponseError = (actionType: string, error: string): DashBoardActionType => ({
    type: DashBoardActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getNotificationsAction = (groupId: string): DashBoardActionType => ({
    type: DashBoardActionTypes.GET_NOTIFICATIONS,
    payload: { groupId },
});
export const getDashboardAction = (groupId: string): DashBoardActionType => ({
    type: DashBoardActionTypes.GET_DASHBOARD_DATA,
    payload: { groupId },
});
