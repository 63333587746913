export enum CompanyActionTypes {
    GET_COMPANIES = '@@Company@GET_COMPANIES',
    GET_COMPANY_BY_ID = '@@Company@GET_COMPANY_BY_ID',
    UPDATE_COMPANY_BY_ID = '@@Company@UPDATE_COMPANY_BY_ID',
    UPLOAD_IMAGE = '@@Company@UPLOAD_IMAGE',
    GET_USERS = '@@Company@GET_USERS',
    GET_USER_BY_ID = '@@Company@GET_USER_BY_ID',
    UPDATE_USER_BY_ID = '@@Company@UPDATE_USER_BY_ID',
    DELETE_USER_BY_ID = '@@Company@DELETE_USER_BY_ID',
    GET_DEVICES = '@@Company@GET_DEVICES',
    SYNC_COMPANIES = '@@Company@SYNC_COMPANIES',
    GET_DEVICE_DETAILS = '@@Company@GET_DEVICE_DETAILS',
    CREATE_SUPPORT_TICKET = '@@Company@CREATE_SUPPORT_TICKET',
    API_RESPONSE_SUCCESS = '@@Company@API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@Company@API_RESPONSE_ERROR',
}
