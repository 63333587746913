import { getCompaniesApi, getCompanyByIdApi, syncCompanyApi, updateCompanyByIdApi, uploadImage } from "helpers/api/companies";
import { SagaIterator } from "redux-saga";
import { CompanyActionTypes } from "./constants";
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { CompaniesApiResponseSuccess, CompaniesApiResponseError } from "./actions";
import { deleteUserByIdApi, getUserByIdApi, getUsersApi, updateUserByIdApi } from "helpers/api/users";
import { createSupportTicketApi, getDeviceDetailsApi, getDevicesApi } from "helpers/api/devices";

/**
 * get companiesList
 * @param {*} payload - groupid
 */
function* getCompaniesSaga({ payload: groupId }: any): SagaIterator {
    try {
        const response = yield call(getCompaniesApi, { groupId });
        const companiesList = response.data?.data;
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.GET_COMPANIES, companiesList));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.GET_COMPANIES, error));
    }
}
function* syncCompaniesSaga(): SagaIterator {
    try {
        const response = yield call(syncCompanyApi);
        const companiesList = response.data?response.data: '';
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.SYNC_COMPANIES, companiesList));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.SYNC_COMPANIES, error));
    }
}
function* getCompanyByIdSaga({ payload: { id } }: any): SagaIterator {
    try {
        const response = yield call(getCompanyByIdApi, id);
        const companyDetailsById = response.data?.data;
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.GET_COMPANY_BY_ID, companyDetailsById));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.GET_COMPANY_BY_ID, error));
    }
}

function* updateCompanyByIdSaga({ payload: { formState } }: any): SagaIterator {
    try {
        const response = yield call(updateCompanyByIdApi, formState); // Removed the object wrapping around formState
        const companyDetailsById = response.data;
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.UPDATE_COMPANY_BY_ID, companyDetailsById));
    } catch (error: any) {
        console.log(error);
        yield put(CompaniesApiResponseError(CompanyActionTypes.UPDATE_COMPANY_BY_ID, error));
    }
}
/**
 * get users
 * @param {*} payload
 */
function* getUsersSaga(): SagaIterator {
    try {
        const response = yield call(getUsersApi, {});
        const usersList = response.data;
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.GET_USERS, usersList));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.GET_USERS, error));
    }
}
function* getUserByIdSaga(action: any): SagaIterator {
    try {
        const response = yield call(getUserByIdApi, action.payload); // Pass userId to the API function
        const user = response.data; // Assuming the response contains a single user
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.GET_USER_BY_ID, user));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.GET_USER_BY_ID, error));
    }
}
function* deleteUserByIdSaga(action: any): SagaIterator {
    try {
        const response = yield call(deleteUserByIdApi, action.payload); // Pass userId to the API function
        const userList = response.data; // Assuming the response contains a single user
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.DELETE_USER_BY_ID, userList));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.DELETE_USER_BY_ID, error));
    }
}
function* updateUserByIdSaga(action: any): SagaIterator {
    try {
        const response = yield call(updateUserByIdApi, action.payload.userData); // Call the API with userId and userData from the action payload
        const updatedUser = response.data; // Assuming the response contains the updated user data
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.UPDATE_USER_BY_ID, updatedUser));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.UPDATE_USER_BY_ID, error));
    }
}

/**
 * get users
 * @param {*} payload
 */
function* getDevicesSaga({ payload: groupId }: any): SagaIterator {
    try {
        const response = yield call(getDevicesApi, { groupId });
        const devicesList = response.data?.data;
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.GET_DEVICES, devicesList));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.GET_DEVICES, error));
    }
}
function* getDeviceDetails(action: any): SagaIterator {
    try {
        const { params } = action.payload;
        const response = yield call(getDeviceDetailsApi, params);
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.GET_DEVICE_DETAILS, response.data?.data));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.GET_DEVICE_DETAILS, error));
    }
}
function* createSupportTicketSaga(action: any): SagaIterator {
    try {
        const params = action.payload?.createTicket;
        const response = yield call(createSupportTicketApi, params);
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.CREATE_SUPPORT_TICKET, response.data));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.CREATE_SUPPORT_TICKET, error));
    }
}
function* uploadImageSaga(action: any): SagaIterator {
    try {
        const response = yield call(uploadImage, action.payload.imageData);
        yield put(CompaniesApiResponseSuccess(CompanyActionTypes.UPLOAD_IMAGE, response.data?.data));
    } catch (error: any) {
        yield put(CompaniesApiResponseError(CompanyActionTypes.UPLOAD_IMAGE, error));
    }
}

// Saga Watch

function* watchGetCompaniesSaga() {
    yield takeEvery(CompanyActionTypes.GET_COMPANIES, getCompaniesSaga);
}
function* watchSyncCompaniesSaga() {
    yield takeEvery(CompanyActionTypes.SYNC_COMPANIES, syncCompaniesSaga);
}
function* watchGetCompanyByIdSaga() {
    yield takeEvery(CompanyActionTypes.GET_COMPANY_BY_ID, getCompanyByIdSaga);
}
function* watchUpdateCompanyByIdSaga() {
    yield takeEvery(CompanyActionTypes.UPDATE_COMPANY_BY_ID, updateCompanyByIdSaga);
}

function* watchGetUsersSaga() {
    yield takeEvery(CompanyActionTypes.GET_USERS, getUsersSaga);
}
function* watchGetUserByIdSaga() {
    yield takeEvery(CompanyActionTypes.GET_USER_BY_ID, getUserByIdSaga);
}
function* watchDeleteUserByIdSaga() {
    yield takeEvery(CompanyActionTypes.DELETE_USER_BY_ID, deleteUserByIdSaga);
}
function* watchUpdateUserById(): SagaIterator {
    yield takeEvery(CompanyActionTypes.UPDATE_USER_BY_ID, updateUserByIdSaga);
}
function* watchGetDevicesSaga() {
    yield takeEvery(CompanyActionTypes.GET_DEVICES, getDevicesSaga);
}
function* watchGetDeviceDetails() {
    yield takeEvery(CompanyActionTypes.GET_DEVICE_DETAILS, getDeviceDetails);
}
function* watchCreateSupportTicketSaga() {
    yield takeEvery(CompanyActionTypes.CREATE_SUPPORT_TICKET, createSupportTicketSaga);
}

function* watchUploadImageSaga() {
    yield takeEvery(CompanyActionTypes.UPLOAD_IMAGE, uploadImageSaga);
}
export default function* companiesSaga() {
    yield all([
        fork(watchGetUsersSaga),
        fork(watchGetUserByIdSaga),
        fork(watchUpdateUserById),
        fork(watchGetCompaniesSaga),
        fork(watchGetCompanyByIdSaga),
        fork(watchUpdateCompanyByIdSaga),
        fork(watchGetDeviceDetails),
        fork(watchGetDevicesSaga),
        fork(watchUploadImageSaga),
        fork(watchCreateSupportTicketSaga),
        fork(watchDeleteUserByIdSaga),
        fork(watchSyncCompaniesSaga),
    ]);
}