import { getCustomerDashboardApi, getNotificationsApi } from "helpers/api/dashboard";
import { SagaIterator } from "redux-saga";
import { DashBoardActionTypes } from "./constants";
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { DashBoardApiResponseError, DashBoardApiResponseSuccess } from "./actions";

/**
 * get Notifications
 * @param {*} payload - groupid
 */
function* getNotificationsSaga({ payload: { groupId }, type }: any): SagaIterator {
    try {
        const response = yield call(getNotificationsApi, { groupId });
        const notifications = response.data?.data;
        yield put(DashBoardApiResponseSuccess(DashBoardActionTypes.GET_NOTIFICATIONS, notifications));
    } catch (error: any) {
        yield put(DashBoardApiResponseError(DashBoardActionTypes.GET_NOTIFICATIONS, error));
    }
}
function* getCustomerDashboardSaga({ payload: { groupId }, type }: any): SagaIterator {
    try {
        const response = yield call(getCustomerDashboardApi, { groupId });
        const dashboardData = response.data?.data;
        yield put(DashBoardApiResponseSuccess(DashBoardActionTypes.GET_DASHBOARD_DATA, dashboardData));
    } catch (error: any) {
        yield put(DashBoardApiResponseError(DashBoardActionTypes.GET_DASHBOARD_DATA, error));
    }
}
// Saga Watch

function* watchGetNotificationsSaga() {
    yield takeEvery(DashBoardActionTypes.GET_NOTIFICATIONS, getNotificationsSaga);
}
function* watchGetCustomerDashboardSaga() {
    yield takeEvery(DashBoardActionTypes.GET_DASHBOARD_DATA, getCustomerDashboardSaga);
}

export default function* dashBoardSaga() {
    yield all([
        fork(watchGetNotificationsSaga),
        fork(watchGetCustomerDashboardSaga),
    ]);
}