import { APICore } from 'helpers/api/apiCore';
import { UserData, CompanyBranding } from './auth';
import { AuthActionTypes } from './constants';

const api = new APICore();

const INIT_STATE = {
    user: api.getLoggedInUser(),
    loading: false,
    resetMailSent: '',
    companyDetails: {
        primaryBgColor: 'linear-gradient(-189.30297094533964deg, #0035c3 0%, #1530b9 9.000000000000002%, #3928a9 22.000000000000004%, #4a24a1 31.000000000000007%, #a0107a 53.000000000000014%, #e70059 100%)',
        fontColor: '#ffffff',
        logo: 'vitale',
        btnBgColor: '#000000',
        btnTextColor: '#ffffff',
        btnHoverColor: '#055141',
        btnTextHoverColor: '#ffffff',
        image: ''
    }
};


type AuthActionType = {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.RESET_PASSWORD_MAIL
        | AuthActionTypes.SIGNUP_USER
        | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.GET_COMPANY_LOGIN
        | AuthActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: UserData;
        error?: string;
    };
};

type State = {
    user?: UserData | {};
    loading?: boolean;
    value?: boolean;
    companyDetails?: CompanyBranding
};

const Auth = (state: State = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        companyDetails: action.payload.data?.branding,
                        userLoggedIn: true,
                        loading: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        userSignUp: true,
                        loading: false,
                    };
                }
                case AuthActionTypes.LOGOUT_USER: {
                    return {
                        ...state,
                        user: null,
                        loading: false,
                        userLogout: true, 
                        userLoggedIn: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        resetPasswordSuccess: action.payload.data,
                        loading: false,
                        passwordReset: true,
                    };
                }
                case AuthActionTypes.CHANGE_PASSWORD: {
                    return {
                        ...state,
                        resetPasswordSuccess: action.payload.data,
                        loading: false,
                        passwordChange: true,
                    };
                }
                case AuthActionTypes.VERIFY_USER: {
                    return {
                        ...state,
                        resetPasswordSuccess: action.payload.data,
                        loading: false,
                        passwordChange: true,
                    };
                }
                case AuthActionTypes.RESET_PASSWORD_MAIL: {
                    return {
                        ...state,
                        resetMailSent: action.payload,
                        loading: false,
                        passwordReset: true,
                    };
                }
                case AuthActionTypes.GET_COMPANY_LOGIN: {
                    return {
                        ...state,
                        companyDetails: action.payload.data,
                        loading: false,
                        passwordReset: false,
                    };
                }
                case AuthActionTypes.UPDATE_COMPANY_BRANDING: {
                    return {
                        ...state,
                        companyDetails: action.payload.data,
                        loading: false,
                        passwordReset: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userLoggedIn: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        userSignUp: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        passwordReset: false,
                    };
                }
                case AuthActionTypes.CHANGE_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        passwordReset: false,
                    };
                }
                case AuthActionTypes.VERIFY_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        passwordReset: false,
                    };
                }
                case AuthActionTypes.RESET_PASSWORD_MAIL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        passwordReset: false,
                    };
                }
                case AuthActionTypes.GET_COMPANY_LOGIN: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        passwordReset: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.LOGIN_USER:
            return { ...state, loading: true, userLoggedIn: false };
        case AuthActionTypes.SIGNUP_USER:
            return { ...state, loading: true, userSignUp: false };
        case AuthActionTypes.LOGOUT_USER:
            return { ...state, loading: true, userLogout: false };
        case AuthActionTypes.RESET_PASSWORD_MAIL:
            return { ...state, loading: false, userLogout: false };
        case AuthActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                userSignUp: false,
                userLoggedIn: false,
                passwordReset: false,
                passwordChange: false,
                resetPasswordSuccess: null,
                companyDetails: {
                    primaryBgColor: 'linear-gradient(-189.30297094533964deg, #0035c3 0%, #1530b9 9.000000000000002%, #3928a9 22.000000000000004%, #4a24a1 31.000000000000007%, #a0107a 53.000000000000014%, #e70059 100%)',
                    fontColor: '#ffffff',
                    logo: 'vitale',
                    organization: 'vitale',
                    btnBgColor: '#000000',
                    btnTextColor: '#ffffff',
                    btnHoverColor: '#055141',
                    btnTextHoverColor: '#ffffff',
                    image: ''
                }
            };
        default:
            return { ...state };
    }
};

export default Auth;
