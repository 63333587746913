import { APICore } from './apiCore';

const api = new APICore();
function getUsersApi(params: {}) {
    const urlRoute = '/users';
    return api.get(`${urlRoute}`, params);
}
function getUserByIdApi(params: { userId: any }) {
    const urlRoute = `/users/${params?.userId}`;
    return api.get(`${urlRoute}`, {});
}
function deleteUserByIdApi(params: { userId: any }) {
    const urlRoute = `/users/${params?.userId}`;
    return api.delete(`${urlRoute}`);
}
function updateUserByIdApi(params: { userData: any }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const urlRoute = `/users`;
    return api.create(`${urlRoute}`, { params })
}
export { getUsersApi, getUserByIdApi, updateUserByIdApi, deleteUserByIdApi }
