import { APICore } from './apiCore';

const api = new APICore();
function login(params: { email: string; password: string }) {
    const urlRoute = '/auth/login/';
    return api.create(`${urlRoute}`, params);
}

function logout() {
    const urlRoute = '/auth/logout/';
    return api.create(`${urlRoute}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    let parm = {
        name: params.fullname,
        email: params.email,
        password: params.password,
    }
    const urlRoute = '/auth/register';
    return api.create(`${urlRoute}`, parm);
}

function forgotPassword(params: { username: string }) {
    let param = {
        email: ""
    }
    param.email = params.username;
    const urlRoute = '/auth/forgot-password';
    return api.create(`${urlRoute}`, param);
}
function sendRestMail(params: { username: string }) {
    let param = {
        email: ""
    }
    param.email = params.username;
    const urlRoute = '/auth/send-verification-email';
    return api.create(`${urlRoute}`, param);
}
function resetPasswordApi(params: { resetData: any }) {
    const newPassword = {
        password: params.resetData?.password
    }
    const urlRoute = '/auth/reset-password?token=' + params?.resetData?.token;
    return api.create(`${urlRoute}`, newPassword);
}
function verifyUserApi(params: { resetData: any }) {
    const newPassword = {
        password: params.resetData?.password
    }
    const urlRoute = '/auth/verify-email?token=' + params?.resetData?.token;
    return api.create(`${urlRoute}`, newPassword);
}

function forgotPasswordConfirm(params: { email: string }) {
    const urlRoute = '/auth/password/reset/confirm/';
    return api.create(`${urlRoute}`, params);
}
function getCompanyByNameApi(params: { company: string }) {
    const urlRoute = '/auth/branding';
    return api.get(`${urlRoute}`, params);
}

export { login, logout, signup, forgotPassword, sendRestMail, forgotPasswordConfirm, getCompanyByNameApi, resetPasswordApi, verifyUserApi };
