import { DashBoardActionTypes } from "./constants";

// import { DashBoardActionType } from "./actions";
const INIT_STATE = {
    notifications: [],
    customerDashboard: [] as CustomerDashboard[],
    loading: false
}
type DashBoardActionType = {
    type:
    | DashBoardActionTypes.API_RESPONSE_SUCCESS
    | DashBoardActionTypes.API_RESPONSE_ERROR
    | DashBoardActionTypes.GET_NOTIFICATIONS
    | DashBoardActionTypes.GET_DASHBOARD_DATA
    payload: {
        actionType?: string;
        data?: any | {};
        error?: string;
    };
};

interface CustomerDashboard {
    notifications: Notification[];
    printerWithLowToners: PrinterWithLowToner[];
    busiestDevices: BusiestDevice[];
}

interface BusiestDevice {
    deviceCounterInfo: DeviceCounterInfo;
    deviceUsageInfo: DeviceCounterInfo;
    DeviceID: number;
    Description: string;
    SerialNumber: string;
    Location: string;
    Manufacturer: string;
    Model: string;
    ModelID: number;
    GroupID: number;
    DateAdded: string;
    DeviceType: number;
    Name: string;
    HostName: string;
    Status: number;
    ManufacturerID: number;
}

interface DeviceCounterInfo {
    DeviceID: number;
    DeviceTotal: number;
    TotalColour: number;
    TotalCopy: number;
    TotalFax: number;
    TotalMono: number;
    TotalPrint: number;
    TotalScan: number;
}

interface PrinterWithLowToner {
    BlackDrumLevel?: any;
    CyanDrumLevel?: any;
    MagentaDrumLevel?: any;
    YellowDrumLevel?: any;
    BlackTonerLevel: number;
    CyanTonerLevel?: number;
    MagentaTonerLevel?: number;
    YellowTonerLevel?: number;
    WasteTonerLevel: number;
    DeviceID: number;
    IPAddress: string;
    MacAddress: string;
    Description: string;
    SerialNumber: string;
    Location: string;
    Manufacturer: string;
    Model: string;
    GroupID: number;
    AssetNumber: string;
    Comments: string;
    LastCommunication: string;
    GroupName?: any;
}
interface Notification {
    deviceId: number;
    errorMessage: string;
    description: string;
    location: string;
}
type State = {
    notifications?: Notification | {};
    loading?: boolean;
};
const Dashboard = (state: State = INIT_STATE, action: DashBoardActionType) => {
    switch (action.type) {
        case DashBoardActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DashBoardActionTypes.GET_NOTIFICATIONS: {
                    return {
                        ...state,
                        notifications: action.payload.data,
                        loading: false,
                    };
                }
                case DashBoardActionTypes.GET_DASHBOARD_DATA: {
                    return {
                        ...state,
                        customerDashboard: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DashBoardActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DashBoardActionTypes.GET_NOTIFICATIONS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case DashBoardActionTypes.GET_DASHBOARD_DATA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case DashBoardActionTypes.GET_NOTIFICATIONS:
            return { ...state, loading: true, };
        case DashBoardActionTypes.GET_DASHBOARD_DATA:
            return { ...state, loading: true, };
        default:
            return { ...state };
    }
};

export default Dashboard;