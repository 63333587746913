import { APICore } from './apiCore';

const api = new APICore();
async function getCompaniesApi(params: { groupId: any }) {
    const urlRoute = '/companies';
    return await api.get(`${urlRoute}`, params.groupId);
}
async function getCompanyByIdApi(params: { id: any }) {
    const urlRoute = `/companies/${params.id}`;
    return await api.get(`${urlRoute}`, '');
}
async function syncCompanyApi() {
    const urlRoute = `/companies/sync`;
    return await api.get(`${urlRoute}`, '');
}
async function updateCompanyByIdApi(payload: { formSate: any, id: any }) {
    const urlRoute = `/companies/${payload.id}`;
    return await api.create(`${urlRoute}`, payload);
}
async function uploadImage(payload: { formSate: any }) {
    const urlRoute = '/upload';
    return await api.create(`${urlRoute}`, payload);
}
export { getCompaniesApi, updateCompanyByIdApi, uploadImage, getCompanyByIdApi, syncCompanyApi }
