import { APICore } from './apiCore';

const api = new APICore();
function getDevicesApi(params: { groupId: string }) {
    const urlRoute = '/devices';
    return api.get(`${urlRoute}`, params?.groupId);
}
function getDeviceDetailsApi(params: any) {
    const urlRoute = `/devices/info/${params?.deviceId}?groupId=${params?.groupID}`;
    return api.get(urlRoute, '');
}
async function createSupportTicketApi(payload: { params: any }) {
    const urlRoute = '/support';
    return await api.create(`${urlRoute}`, payload);

} export { getDevicesApi, getDeviceDetailsApi, createSupportTicketApi }
