import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Companies from './companies/reducers';
import Dashboard from './dashboard/reducers';
import Layout from './layout/reducers';

export default combineReducers({
    Auth,
    Layout,
    Dashboard,
    Companies
});
