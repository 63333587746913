import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, AUTH_SESSION_KEY, setAuthorization } from 'helpers/api/apiCore';
import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
} from 'helpers';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';
import { getCompanyByNameApi, resetPasswordApi, sendRestMail, verifyUserApi } from 'helpers/api/auth';

type UserData = {
    payload: {
        username: string;
        password: string;
        fullname: string;
        email: string;
    };
    type: string;
};
export interface UserDetail {
    id?: number;
    email?: string;
    name?: string;
    role?: string;
    isEmailVerified?: boolean;
    companyId?: number;
    createdAt?: string;
    updatedAt?: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }: UserData): SagaIterator {
    try {
        let email = username
        const response = yield call(loginApi, { email, password });
        // const user = response.data;
        const user = response.data.user;
        user.token = response.data?.tokens.access?.token;
        user.branding = response.data?.branding;
        // NOTE - You can change this according to response format from your api
        api.setLoggedInUser(user);
        setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
        response.data?.branding ?? (yield put(authApiResponseSuccess(AuthActionTypes.GET_COMPANY_LOGIN, response.data?.branding)))
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        sessionStorage.removeItem(AUTH_SESSION_KEY);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: { fullname, email, password } }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, { fullname, email, password });
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { username } }: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, { username });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}
function* sendRestMailSaga({ payload: { username } }: UserData): SagaIterator {
    try {
        const response = yield call(sendRestMail, { username });
        yield put(authApiResponseSuccess(AuthActionTypes.RESET_PASSWORD_MAIL, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.RESET_PASSWORD_MAIL, error));
    }
}
function* resetPasswordSaga({ payload: resetData }: any): SagaIterator {
    try {
        const response = yield call(resetPasswordApi, { resetData });
        yield put(authApiResponseSuccess(AuthActionTypes.CHANGE_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.CHANGE_PASSWORD, error));
    }
}
function* verifyUserSaga({ payload: resetData }: any): SagaIterator {
    try {
        const response = yield call(verifyUserApi, { resetData });
        yield put(authApiResponseSuccess(AuthActionTypes.VERIFY_USER, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.VERIFY_USER, error));
    }
}

function* getCompanyByNameSaga({ payload: company }: any): SagaIterator {
    try {
        // const { params } = payload;
        const response = yield call(getCompanyByNameApi, { company });
        response ? yield put(authApiResponseSuccess(AuthActionTypes.GET_COMPANY_LOGIN, response.data?.data)) : console.log(response)
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.GET_COMPANY_LOGIN, error));
        console.log(error);
    }
}
function* updateCompanyBrandingSaga({ payload: companyBranding }: any): SagaIterator {
    try {
        yield put(authApiResponseSuccess(AuthActionTypes.UPDATE_COMPANY_BRANDING, companyBranding))
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.UPDATE_COMPANY_BRANDING, error));
        console.log(error);
    }
}
export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword() {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}
export function* watchSendRestMailSaga() {
    yield takeEvery(AuthActionTypes.RESET_PASSWORD_MAIL, sendRestMailSaga);
}
export function* watchResetPasswordSaga() {
    yield takeEvery(AuthActionTypes.CHANGE_PASSWORD, resetPasswordSaga);
}
export function* watchVerifyUserSaga() {
    yield takeEvery(AuthActionTypes.VERIFY_USER, verifyUserSaga);
}
export function* watchGetCompanyByNameSaga() {
    yield takeEvery(AuthActionTypes.GET_COMPANY_LOGIN, getCompanyByNameSaga);
}
export function* watchUpdateCompanyBrandingSaga() {
    yield takeEvery(AuthActionTypes.UPDATE_COMPANY_BRANDING, updateCompanyBrandingSaga);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogout), fork(watchSignup), fork(watchForgotPassword), fork(watchGetCompanyByNameSaga), fork(watchUpdateCompanyBrandingSaga), fork(watchSendRestMailSaga), fork(watchResetPasswordSaga), fork(watchVerifyUserSaga)]);
}

export default authSaga;
